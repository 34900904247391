import PropTypes from "prop-types"
import React from "react"
import Button from "@components/Button/Button"
import ButtonGroupMap from "../Button/ButtonGroupMap"
import CTA from "./CTA"
import MarkdownViewer from "@components/MarkdownViewer"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var showdown = require("showdown")

function createHtml(html) {
  return { __html: html }
}

const converter = new showdown.Converter()

var classNames = require("classnames")

class GetStarted extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let buttonColumns
    // One or multiple buttons
    if (this.props.buttons) {
      buttonColumns = (
        <div className="column">
          <ButtonGroupMap isCentered buttons={this.props.buttons} />
        </div>
      )
    } else {
      buttonColumns = (
        <div className="column">
          <Button
            contained
            href={this.props.buttonUrl ? this.props.buttonUrl : "undefined"}
            buttonText={
              this.props.buttonText ? this.props.buttonText : "undefined"
            }
          />
        </div>
      )
    }

    // CSS classes
    let headingClasses = classNames("column", {
      "has-text-centered": this.props.centerHeading
    })
    let textClasses = classNames("column", {
      "has-text-centered": this.props.centerText
    })

    return (
      <CTA
        className={this.props.className ? this.props.className : ""}
        sideColumnIs={this.props.sideColumnIs}
        headingSideColumnIs={this.props.headingSideColumnIs}
        headingColumns={
          <div className={headingClasses}>
            {this.props.subheading && <h4>{this.props.subheading}</h4>}
            <h3>{this.props.heading}</h3>
          </div>
        }
        textColumns={
          <div
            className={textClasses}
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(this.props.paragraph)
            )}></div>
        }
        buttonColumns={buttonColumns}
      />
    )
  }
}

GetStarted.propTypes = {
  className: PropTypes.string,
  centerHeading: PropTypes.bool,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  centerText: PropTypes.bool,
  paragraph: PropTypes.element,
  buttons: PropTypes.array,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  sideColumnIs: PropTypes.number,
  headingSideColumnIs: PropTypes.number
}

export default GetStarted
